import { Card, Col, Row, Spin } from 'antd';
import {
  EventItem,
  FindAndSearchUsers,
  GroupItem,
  PrimaryButton,
  Text,
} from '../../../components';
import { useQuery } from '@apollo/client';
import { GET_FEATURED_ITEMS, Query } from '../../../graphql';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { FeedItem } from '../Feed';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

const Home = () => {
  const navigate = useNavigate();
  const { md } = useBreakpoint();
  const { data, loading, refetch } = useQuery<Query>(GET_FEATURED_ITEMS);

  const hasEvents = useMemo(() => !!data?.getFeaturedItems.events?.length, [data]);

  const Spinner = () => (
    <Row style={{ padding: '6em' }} justify={'center'}>
      <Spin />
    </Row>
  );

  const Events = ({ bordered }: { bordered?: boolean }) => {
    return (
      <Card
        style={bordered ? {} : { border: 'none', boxShadow: 'none' }}
        bodyStyle={{
          padding: bordered ? 16 : 0,
        }}
      >
        <Row style={{ gap: 12, display: 'grid' }}>
          <Text variant={'lgStrong'}>Events</Text>
          <Row
            gutter={[12, 12]}
            style={{ marginTop: 4, padding: loading ? '6em' : 0 }}
            justify={loading ? 'center' : 'start'}
          >
            {loading ? (
              <Spin />
            ) : (
              data?.getFeaturedItems?.events?.map((event) => (
                <Col xs={24} sm={8} lg={24} key={event.id}>
                  <EventItem event={event} />
                </Col>
              ))
            )}
          </Row>
        </Row>
      </Card>
    );
  };

  return (
    <Row gutter={[16, 20]}>
      <Col xs={24} lg={hasEvents ? 16 : 24}>
        <Row gutter={[16, 20]}>
          <Col span={24}>
            <Card
              className={'bg-5'}
              style={{ height: md ? 350 : 350, backgroundPosition: 'center' }}
              bodyStyle={{ display: 'grid', position: 'absolute', bottom: 0 }}
            >
              <Text variant={'heading3'} color={'white'}>
                {data?.getFeaturedItems.hero.heading}
              </Text>
              <Text color={'black1'} style={{ margin: '10px 0', maxWidth: 800 }}>
                {data?.getFeaturedItems.hero.description}
              </Text>
              <PrimaryButton
                height={32}
                fontSize={14}
                onClick={() =>
                  data?.getFeaturedItems.hero.buttonUrl
                    ? navigate(data?.getFeaturedItems.hero.buttonUrl)
                    : {}
                }
              >
                {data?.getFeaturedItems.hero.buttonText}
              </PrimaryButton>
            </Card>
          </Col>
          <Col span={24}>
            <FindAndSearchUsers />
          </Col>
          <Col xs={24} lg={0}>
            <Events bordered={false} />
          </Col>
          <Col span={24}>
            <Text variant={'smNormal'} color={'black4'}>
              Featured community groups
            </Text>
            <Row
              gutter={[12, 12]}
              style={{ marginTop: 4, padding: loading ? '6em' : 0 }}
              justify={loading ? 'center' : 'start'}
            >
              {loading ? (
                <Spin />
              ) : (
                data?.getFeaturedItems.groups?.map((group) => (
                  <Col xs={24} sm={8} key={group.id}>
                    <GroupItem group={group} refetch={refetch} />
                  </Col>
                ))
              )}
            </Row>
          </Col>
          {(loading || !!data?.getFeaturedItems.stories?.length) && (
            <Col span={24} style={{ display: 'grid' }}>
              {loading ? (
                <Spinner />
              ) : !!data?.getFeaturedItems.stories?.length ? (
                <>
                  <Text variant={'smNormal'} color={'black4'}>
                    Featured posts and fundraisings
                  </Text>
                  <Row style={{ marginTop: 4 }} justify={'center'}>
                    {data?.getFeaturedItems.stories?.map((story) => (
                      <Col span={24} key={story.id}>
                        <FeedItem story={story} refetch={refetch} />
                      </Col>
                    ))}
                  </Row>
                </>
              ) : (
                <></>
              )}
            </Col>
          )}
        </Row>
      </Col>
      {hasEvents && <Col xs={0} lg={8}>
        <Events bordered />
      </Col>}
    </Row>
  );
};

export default Home;
