import * as React from 'react';
import { useState, useMemo, useRef, useEffect } from 'react';
import Map, {
  FullscreenControl,
  Marker,
  NavigationControl,
  Popup,
  Source,
  Layer,
  MapRef,
} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { colors, getRoleText, getUserLocation } from '../../utils';
import { useQuery } from '@apollo/client';
import {
  Event,
  EventStatus,
  GET_ALL_USERS,
  GET_EVENTS,
  Query,
  QueryGetEventsArgs,
  QueryGetUsersArgs,
  User,
  UserRole,
  UserStatus,
} from '../../graphql';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Spin } from 'antd';
import { Text } from '../Text';
import { PrimaryButton } from '../Button';
import { format } from 'date-fns';
import ocl_logo from '../../assets/images/ocl_logo.png';

export default function UsersMap({ roles }: { roles: UserRole[] }) {
  const navigate = useNavigate();
  const { data: usersData, loading: usersLoading } = useQuery<
    Query,
    QueryGetUsersArgs
  >(GET_ALL_USERS, {
    variables: { status: [UserStatus.Approved], roles },
  });
  const { data: eventsData, loading: eventsLoading } = useQuery<
    Query,
    QueryGetEventsArgs
  >(GET_EVENTS, {
    variables: {
      type: 'UPCOMING',
      status: EventStatus.Published,
    },
    fetchPolicy: 'network-only',
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [userPopupInfo, setUserPopupInfo] = useState<User | null>(null);
  const [eventPopupInfo, setEventPopupInfo] = useState<Event | null>(null);

  // Function to calculate offset for overlapping markers
  const calculateOffset = (index: number, total: number) => {
    const angle = (index / total) * 2 * Math.PI;
    const offsetDistance = 0.01; // Adjust this value to control the offset distance
    return {
      offsetLat: offsetDistance * Math.cos(angle),
      offsetLong: offsetDistance * Math.sin(angle),
    };
  };

  const groupByCoordinates = (items: { lat: number; long: number }[]) => {
    const groups: { [key: string]: any[] } = {};
    items.forEach((item) => {
      const key = `${item.lat},${item.long}`;
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(item);
    });
    return groups;
  };

  const userGroups = useMemo(
    () =>
      groupByCoordinates(
        usersData?.getUsers?.data?.map((user) => ({
          lat: Number(user.profile?.lat),
          long: Number(user.profile?.long),
          user,
        })) || [],
      ),
    [usersData?.getUsers?.data],
  );

  const geojson = useMemo(() => {
    const features = Object.values(userGroups).flatMap((group, i) =>
      group.map((user, j) => {

        const { offsetLat, offsetLong } = calculateOffset(j, group.length);
        return ({
          type: 'Feature',
          properties: {
            id: user.user.id,
            role: user.user.role,
            picture: user.user.profile?.picture,
          },
          geometry: {
            type: 'Point',
            coordinates: [Number(user.long + offsetLong), Number(user.lat + offsetLat)],
          },
        });
      }));
    return { type: 'FeatureCollection', features: features || [] };
  }, [userGroups]);

  const eventGroups = useMemo(
    () =>
      groupByCoordinates(
        eventsData?.getEvents?.data?.map((event) => ({
          lat: event.location.lat,
          long: event.location.long,
          event,
        })) || [],
      ),
    [eventsData?.getEvents?.data],
  );

  const eventPins = useMemo(
    () =>
      Object.values(eventGroups).flatMap((group, i) =>
        group.map((item, j) => {
          const { offsetLat, offsetLong } = calculateOffset(j, group.length);
          return (
            <Marker
              key={`event-marker-${item.event.id}-${j}`}
              longitude={item.long + offsetLong}
              latitude={item.lat + offsetLat}
              anchor="bottom"
              onClick={(e) => {
                e.originalEvent.stopPropagation();
                setEventPopupInfo(item.event);
                setUserPopupInfo(null);
              }}
            />
          );
        }),
      ),
    [eventGroups],
  );

  const mapRef = useRef<MapRef | null>(null);

  useEffect(() => {
    if (!mapRef.current || !geojson.features) return;

    // const map = mapRef.current;
    //
    // Add user images to the map
    // geojson.features.forEach((feature) => {
    //   const { id, picture } = feature.properties;
    //
    //   if (picture) {
    //     if (!map.hasImage(id)) {
    //       console.log('adding', id);
    //       createCircularImage(picture, 36, (circularImage: string) => {
    //         console.log('circl', circularImage);
    //         map.loadImage(circularImage, (error, image) => {
    //           if (error) throw error;
    //           if (!map.hasImage(id)) {
    //             // @ts-ignore
    //             map.addImage(id, image);
    //           }
    //         });
    //       });
    //     }
    //
    //     // map.loadImage(picture, (error, image) => {
    //     //   if (error) {
    //     //     console.error(`Failed to load image for user ${id}`, error);
    //     //     return;
    //     //   }
    //     //   if (image && !map.hasImage(id)) {
    //     //     map.addImage(id, image);
    //     //   }
    //     // });
    //   }
    // });
  }, [mapRef.current, geojson]);

  return usersLoading || eventsLoading ? (
    <Row justify={'center'} style={{ padding: '6em' }}>
      <Spin />
    </Row>
  ) : (
    <div>
      <Map
        ref={mapRef}
        onLoad={() => {
          const map = mapRef.current;

          if (map) {
            geojson.features.forEach((feature, index) => {
              const { id, role, picture } = feature.properties;

              if (picture) {
                if (!map.hasImage(id)) {
                  console.log('adding', id);
                  createCircularImage(picture, 36, role, (circularImage: string) => {
                    map.loadImage(circularImage, (error, image) => {
                      if (index === geojson.features.length - 1) {
                        setTimeout(() => setLoading(false), 1000);
                      }

                      if (error) throw error;
                      if (!map.hasImage(id)) {
                        // @ts-ignore
                        map.addImage(id, image);

                      }
                    });
                  });
                }

                // map.loadImage(picture, (error, image) => {
                //   if (error) {
                //     console.error(`Failed to load image for user ${id}`, error);
                //     return;
                //   }
                //   if (image && !map.hasImage(id)) {
                //     map.addImage(id, image);
                //   }
                // });
              }
            });

            // setLoading(false);
          }
        }}
        initialViewState={{
          latitude: 50,
          zoom: 2,
          bearing: 0,
          pitch: 0,
        }}
        style={{ height: 400, borderRadius: 12, opacity: loading ? 0.5 : 1 }}
        onClick={(event) => {
          const feature = event.features?.find(
            (f) => f.layer?.id === 'unclustered-point',
          );

          if (feature) {
            const user = usersData?.getUsers?.data.find(
              // @ts-ignore
              (u) => u.id === feature.properties.id,
            );

            setUserPopupInfo(
              user?.id === userPopupInfo?.id ? null : user ?? null,
            );
            setEventPopupInfo(null);
          }
        }}
        interactiveLayerIds={['unclustered-point']}
        mapStyle="mapbox://styles/mapbox/light-v11"
        mapboxAccessToken={
          'pk.eyJ1IjoiYmFydGVybWVucyIsImEiOiJjbHoxMWszY2EybG13MmlzaDN0NDdld2ZrIn0.JJ16iFMT4rJI6V7Mjd68aw'
        }
      >
        <FullscreenControl position="top-left" />
        <NavigationControl position="top-left" />

        {eventPins}

        <Source
          id="users"
          type="geojson"
          data={geojson}
          cluster={true}
          clusterMaxZoom={14} // Maximum zoom to cluster points
          clusterRadius={50} // Radius of each cluster
        >
          {/* Cluster layer */}
          <Layer
            id="clusters"
            type="circle"
            source="users"
            filter={['has', 'point_count']}
            paint={{
              'circle-color': colors.primary,
              'circle-radius': [
                'step',
                ['get', 'point_count'],
                20,
                100,
                30,
                750,
                40,
              ],
            }}
          />
          {/* Cluster count */}
          <Layer
            id="cluster-count"
            type="symbol"
            source="users"
            filter={['has', 'point_count']}
            layout={{
              'text-field': '{point_count_abbreviated}',
              'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
              'text-size': 12,
            }}
            paint={{
              'text-color': 'white',
            }}
          />

          {/* Unclustered points */}
          {/*<Layer*/}
          {/*  id="unclustered-point"*/}
          {/*  type="circle"*/}
          {/*  source="users"*/}
          {/*  filter={['!', ['has', 'point_count']]}*/}
          {/*  paint={{*/}
          {/*    'circle-color': colors.blue6,*/}
          {/*    'circle-radius': 8,*/}
          {/*  }}*/}
          {/*/>*/}

          <Layer
            id="unclustered-point"
            type="symbol"
            source="users"
            filter={['!', ['has', 'point_count']]}
            layout={{
              'icon-image': ['get', 'id'], // Use the id to match the image added with addImage
              'icon-size': 1,
            }}
          />
        </Source>

        {!!userPopupInfo?.id && (
          <Popup
            anchor="top"
            closeButton={false}
            longitude={Number(userPopupInfo.profile?.long)}
            latitude={Number(userPopupInfo.profile?.lat)}
            onClose={() => setUserPopupInfo(null)}
          >
            <div style={{ display: 'grid', gap: 16, minWidth: 190 }}>
              <Row gutter={14} align={'middle'} style={{ flexWrap: 'nowrap' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 16.5,
                    width: 16.5,
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    cursor: 'pointer',
                    zIndex: '2',
                  }}
                  onClick={() => setUserPopupInfo(null)}
                >
                  <img
                    src={require('../../assets/images/closeIcon.png')}
                    style={{ height: 8.5, width: 8.5 }}
                  />
                </div>
                <Col>
                  <img
                    src={userPopupInfo.profile?.picture || ocl_logo}
                    style={{
                      height: 40,
                      width: 40,
                      objectFit: 'cover',
                      borderRadius: 40,
                    }}
                  />
                </Col>
                <Col style={{ display: 'grid' }}>
                  <Text
                    variant={'baseStrong'}
                    lineHeight={18}
                    style={{ paddingRight: '10px' }}
                  >{`${userPopupInfo.firstName} ${userPopupInfo.lastName}`}</Text>
                  <Text
                    variant={'smNormal'}
                    lineHeight={20}
                    color={'black4'}
                    style={{ textWrap: 'nowrap' }}
                  >
                    {getRoleText(userPopupInfo.role)}
                  </Text>
                </Col>
              </Row>
              <div
                style={{
                  padding: '2px 10px',
                  background: colors.blue1,
                  borderRadius: 4,
                  width: 'fit-content',
                }}
              >
                <Text variant={'smMedium'} color={'blue6'} lineHeight={'normal'}>
                  {getUserLocation(userPopupInfo.profile?.location)}
                </Text>
              </div>
              <PrimaryButton
                height={26}
                borderRadius={4}
                style={{ fontSize: 12 }}
                block
                onClick={() => navigate(`/user/${userPopupInfo?.id}`)}
              >
                View profile
              </PrimaryButton>
            </div>
          </Popup>
        )}
        {eventPopupInfo && (
          <Popup
            className={'event-popup'}
            anchor="top"
            closeButton={false}
            style={{ zIndex: 100 }}
            longitude={eventPopupInfo.location.long}
            latitude={eventPopupInfo.location.lat}
            onClose={() => setEventPopupInfo(null)}
          >
            <div style={{ minWidth: 230 }}>
              <Row>
                <Col
                  span={24}
                  style={{
                    height: 100,
                    borderTopRightRadius: 10,
                    borderTopLeftRadius: 10,
                    background: `url(${eventPopupInfo.banner}) no-repeat`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 16.5,
                      width: 16.5,
                      background: 'rgba(255, 255, 255, 0.50)',
                      borderRadius: '100%',
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      cursor: 'pointer',
                      zIndex: '2',
                    }}
                    onClick={() => setEventPopupInfo(null)}
                  >
                    <img
                      src={require('../../assets/images/closeIcon.png')}
                      style={{ height: 8.5, width: 8.5 }}
                    />
                  </div>
                </Col>
              </Row>
              <Row style={{ padding: 12 }} gutter={[0, 16]}>
                <Col style={{ display: 'grid' }} span={24}>
                  <Text
                    variant={'baseStrong'}
                    lineHeight={18}
                    style={{ paddingRight: '10px' }}
                  >
                    {eventPopupInfo.title}
                  </Text>
                  <Text
                    variant={'smNormal'}
                    lineHeight={20}
                    color={'black4'}
                    style={{ textWrap: 'nowrap' }}
                  >
                    {!!eventPopupInfo?.start
                      ? `${format(
                        new Date(Number(eventPopupInfo.start)),
                        'MMM, d',
                      )}${
                        !!eventPopupInfo.end
                          ? ` - ${format(
                            new Date(Number(eventPopupInfo.end)),
                            'MMM, d',
                          )}`
                          : ''
                      }`
                      : ''}
                  </Text>
                </Col>
                <Col span={24}>
                  <div
                    style={{
                      padding: '2px 10px',
                      background: colors.blue1,
                      borderRadius: 4,
                      width: 'fit-content',
                    }}
                  >
                    <Text
                      variant={'smMedium'}
                      color={'blue6'}
                      lineHeight={'normal'}
                    >
                      {eventPopupInfo.location.title},{' '}
                      {eventPopupInfo.location.description}
                    </Text>
                  </div>
                </Col>
                <Col span={24}>
                  <PrimaryButton
                    height={26}
                    borderRadius={4}
                    style={{ fontSize: 12 }}
                    block
                    onClick={() => navigate(`events/${eventPopupInfo?.id}`)}
                  >
                    View event
                  </PrimaryButton>
                </Col>
              </Row>
            </div>
          </Popup>
        )}
      </Map>

      {loading && <div style={{
        background: 'white',
        borderRadius: 12,
        padding: 16,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
      }}>
        <Text center fontSize={14} color={'black6'}>We're loading the community, please wait a moment!</Text>
        <Spin size={'small'} />
      </div>}
    </div>
  );
}

const userBorderColor = {
  [UserRole.Storyteller]: '#08F7EB',
  [UserRole.Guardian]: '#ED7064',
  [UserRole.Advocate]: '#10303B',
  [UserRole.Ambassador]: '#F8C491',
  [UserRole.Admin]: '#F6F6F6',
};

function createCircularImage(url: string, size: number, role: UserRole, callback: any) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const img = new Image();

  canvas.width = size;
  canvas.height = size;

  img.crossOrigin = 'anonymous';
  img.onload = () => {
    if (context) {
      context.imageSmoothingEnabled = true;
      context.imageSmoothingQuality = 'high';  // Set the highest quality for smoothing
      context.fillStyle = 'white';
      context.strokeStyle = userBorderColor[role];

      // Draw circular mask
      context.beginPath();
      context.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
      context.closePath();
      context.clip();
      context.fill();

      // Resize the image to fit within the circular area, keeping the aspect ratio
      const imgSize = Math.min(img.width, img.height);  // To maintain aspect ratio
      const offsetX = (img.width - imgSize) / 2;  // Center the image horizontally
      const offsetY = (img.height - imgSize) / 2;  // Center the image vertically

      // Draw the image inside the circular area, scale it to the high-resolution canvas size
      context.drawImage(img, offsetX, offsetY, imgSize, imgSize, 0, 0, size, size);

      // Optionally, add a border around the circle
      context.lineWidth = 4;
      context.stroke();
    }

    // Scale down the canvas to the original size and get the base64 image
    const dataUrl = canvas.toDataURL('image/png');
    callback(dataUrl);
  };

  img.src = url;
}
